<template>
    <div class="listDiv"> 
  
               
                   <StaffItem v-for="item of staffArr" :key="item.id" :staff='item'></StaffItem> 
     
             
    </div>
    
    
</template>
<script>
import StaffItem from 'components/staffList/StaffItem.vue'
   export default {
        name:'StaffList',
        components:{
            StaffItem
        },
        props:{
            staffArr:{
                type:Array
            }
        },
        data(){
            return{
              
            }
        },
         mounted(){
            document.querySelector('body').setAttribute('style','background-color:#FAFAFA')
        },
        beforeDestroy(){
            document.querySelector('body').removeAttribute('style')
        },
    
   
    }
</script>
<style scoped>
    .listDiv{
        height: 100%;
    }
</style>

