<template>
    <div id="NavBar">
            <div class="nav_left"  @click="backLast()">
                    <slot name="left"></slot>
            </div>
            <div class="nav_center">
                    <slot name="center"></slot>
            </div>
            <div class="nav_right">
                    <slot name="right"></slot>
            </div>
    </div>
</template>
<script>
export default {
    name:'NavBar',
    methods:{
        backLast(){
                this.$router.back();
        }
    }
}
</script>
<style>
     #NavBar{
     text-align: center;
     height: 95px;
     position: absolute;
     top: 0;
     right: 0;
     left: 0;
     width: 100%;
     display: flex;
    align-items: center;   
    justify-content: center;  
    border: solid 1px #EEEEEE;
    background: #ffffff;
 }
 .nav_right,.nav_left{
     width: 60px;
 }
 
 .nav_center{
     flex: 1;
     font-size: 36px;
     color: #333333;
    font-weight: 500;

 }
</style>