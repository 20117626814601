<template>
    <div>
            <div class="staffDiv"  @click="toMessage">
                <div :style="leftTopColor" class="leftTop"></div>
                <img :src="getStateImage" alt="" class="checkImg">
                <p class="name">{{staff.title}}</p>
                <p class="codeName">{{staff.username}}</p>
                <p class="codeNum codeNumT">员工编号 : {{staff.account}}</p>
                <p class="codeNum codeNumT codeNumP">考核日期 : {{startTime}}--{{endTime}}</p>
                <p class="reject" v-if="staff.refuseFlag == 1">被驳回</p>
            </div>
    </div>
    
    
</template>
<script>
   export default {
        name:'StaffItem',
        props:{
            staff:{
                type:Object
            }
        },
        data(){
            return{
            }
        },
        computed:{
            startTime(){
                return this.staff.startTime.substring(0,10)
            },
            endTime(){
                return this.staff.endTime.substring(0,10)
            },
            leftTopColor(){
                let style ;
                let state =  this.staff.state;
                if(state == 0){
                    style = {'background':'#38D4D8'};
                    
                }else if(state == 1){
                    style = {'background':'#F4AB48'};
                   
                }else if(state == 2){
                    style = {'background':'#4BA6FF'};
                }
                else if(state == 3){
                    style = {'background':'#FB7176'};
                }
                return style;
            },
            getStateImage(){
                 let imgPath ;
                let state =  this.staff.state;
                if(state == 0){
                    imgPath = require('../../assets/image/checkStatus/notRemark.png');
                    
                }else if(state == 1){
                    imgPath = require('../../assets/image/checkStatus/remarking.png');
                   
                }else if(state == 2){
                    imgPath = require('../../assets/image/checkStatus/remarked.png');
                }else if(state == 3){
                    imgPath = require('../../assets/image/checkStatus/selfRemark.png');
                }
                return imgPath;
            }
        },
        methods:{
             toMessage(){
                 let id = this.staff.id;
                 localStorage.setItem('taskId',id);
                    this.$router.push({
                          name:'CheckMessage',
                          path:'/checkList/checkMessage'
                    });
                    
             }
        },
         mounted(){
            document.querySelector('body').setAttribute('style','background-color:#FAFAFA')
        },
        beforeDestroy(){
            document.querySelector('body').removeAttribute('style')
        }
    }
</script>
<style scoped>
    .staffDiv{

        margin: 0px auto;
        margin-bottom: 40px;
        width: 702px;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        box-shadow: 0px 5px 20px 0px rgba(38, 140, 228, 0.2);
        border-radius: 4px;
        position: relative;
    }
    .leftTop{
        width: 8px;
        height: 105px;
        border-radius: 4px;
        position: absolute;
        top: 0;
        left: 0;
    }
    .checkImg{
        width: 103px;
        position: absolute;
        top: 0;
        right: 0;
    }
    .name{
        margin: 38px 0px 51px 43px;
        font-size: 36px;
        font-weight: bold;
        color: #333333;
    }
    .codeName{
        margin-left: 40px;
        font-size: 30px;
        font-weight: 400;
        color: #333333;
    }
    .codeNum{
        margin-left: 40px;
        font-size: 30px;
        font-weight: 400;
        color: #666666;
    }
    .codeNumT{
        margin-top: 31px;
    }
    .codeNumP{
        margin-bottom: 20px;
    }
    .reject{
        color: red;
        font-size: 30px;
        font-weight: 400;
        position: absolute;
        right: 43px;
        bottom: 20px;
    }
</style>
