<template>
    <div class="checkList">
        <div v-if="isFathShow">
            <van-sticky>
            <NavBar>
                <img src="~assets/image/home/left.png" alt="" slot="left" class="left">
                <span slot="center">员工点评列表</span>
            </NavBar>
            </van-sticky>

            <!-- <div class="StaffList">
                <StaffList :staffArr='staffArr'>
                </StaffList>
            </div> -->
             
          <div class="StaffList">
             <van-pull-refresh v-model="refreshing" @refresh="onRefresh" success-text="刷新成功">
                <van-list
                    v-model="loading"  
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                >    
                    <StaffList :staffArr='staffArr'>
                    </StaffList>      
                </van-list>
               </van-pull-refresh>
            </div> 
        </div>
         <router-view />    
    </div>
   
    
   
</template>
<script>
import  NavBar from 'components/navBar/NavBar.vue'
import StaffList from 'components/staffList/StaffList.vue'
import {getList} from '../../network/normal'

export default {
   name:'CheckList',
   components:{
       NavBar,
       StaffList
   },
   data(){
       return{
           isFathShow:true,
            staffArr:[],
            loading: false,
            finished: false,
            pageNum:1,
            pageSize:5,
            total:0,
            refreshing:false
       }
   },
  watch:{
      $route(newVal){
          if(newVal.path.indexOf('checkMessage') != -1){
              this.isFathShow = false;
          }else{
              this.isFathShow = true;
          }
          if(newVal.name == 'CheckList'){
              this.onLoad();
          }
      }
  },
  mounted() {
      if(this.$route.path.indexOf('checkMessage') != -1){
          this.isFathShow = false;
      }
  },
  methods: {

        getDataList(){
            this.staffArr = [];
             let  state = this.$route.query.state;
            let username = this.$route.query.username
            let account = this.$route.query.account    
                getList(state,username,account,this.pageNum,this.pageSize).then((res)=>{
                        if(res.success){    
                            if(res.data.isLastPage){
                               
                                this.finished = true
                            } 
                            this.total = res.total
                            
                            for (const iterator of res.data.list) {
                                this.staffArr.push(iterator)
                            }
                            this.loading = false;
                            this.refreshing = false
                            if (this.staffArr.length >= res.total) {
                                this.finished = true;		// 结束加载状态
                            }
                            this.pageNum++;				    
                        }else{
                            this.$toast.fail(res.msg)
                        }
                })
        },
        onLoad() {   
            this.getDataList();				
            
        },
        onRefresh(){
            
            this.staffArr = [];
            this.finished = false;
            this.loading = true;
            this.pageNum = 1;
            this.onLoad();
        }
 
  }



  
}
</script>
<style scoped>
    .checkList{
        overflow: hidden;
    }
    .left{
        margin-left: 24px;
        width: 23px;
        height: 41px; 
    }
    .StaffList{
        margin-top: 120px;
       
    }
    .van-pull-refresh{
        height:calc(100vh - 120px);
        overflow: scroll;
    }
</style>
